


































































































import Vue from "vue";
import { Action } from "vuex-class";
import { Component } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { minLength, maxLength, required } from "vuelidate/lib/validators";
import api from "@/core/utils/api";

@Component({
  mixins: [validationMixin],
  validations: {
    userCodeInput: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6),
    },
  },
})
export default class ConfirmDevice extends Vue {
  @Action("displaySnackbar") displaySnackbar!: (s: string) => void;
  @Action("clearEverything") clearEverything!: (reroute: boolean) => void;
  @Action("profile/verifyDeviceCode") verifyDeviceCode!: (
    data: any,
  ) => Promise<void>;

  userCodeInput: number | undefined = 0;

  step = 1;
  loading = true;
  done = false;

  userCodeFormatted = (userCode: number) => {
    if (userCode)
      return (
        userCode.toString().substring(0, 3) +
        " " +
        userCode.toString().substring(3)
      );

    return "- - - - - -";
  };

  get userCodeErrors() {
    const errors: any[] = [];
    if (!this.$v.userCodeInput!.$dirty) return errors;
    if (!this.$v.userCodeInput!.required)
      errors.push(this.$t("confirmDeviceSection.codeRequired"));
    if (!this.$v.userCodeInput!.minLength)
      errors.push(this.$t("confirmDeviceSection.codeMin", { min: 6 }));
    !this.$v.userCodeInput!.maxLength &&
      errors.push(this.$t("confirmDeviceSection.codeMax", { max: 6 }));

    return errors;
  }

  async validateCode(): Promise<void> {
    console.log("validating");

    this.$v.$touch();
    if (!this.$v.$invalid) this.step = 2;
  }

  async skipOnSuccessReturn() {
    const { deviceCode, success } = this.$route.query;
    if (deviceCode && success) this.done = true;
    this.loading = false;
  }

  async submit(): Promise<void> {
    this.loading = true;
    let deviceCode = "";
    console.log("submitting");

    this.$v.$touch();
    if (!this.$v.$invalid) {
      console.log("valid form");
      try {
        const end = "/api/Account/VerifyDeviceCode";
        const data = { userCode: this.userCodeInput };
        const res = (await api.post(end, data)) as any;
        deviceCode = res.deviceCode;

        console.log("device code forwarded to auth");

        await this.$router.push(`/auth/login?deviceCode=${deviceCode}`);
      } catch (error) {
        this.displaySnackbar(this.$t("confirmDeviceSection.error").toString());
      }
    } else
      this.displaySnackbar(
        this.$t("confirmDeviceSection.couldNotConfirm").toString(),
      );

    this.loading = false;
    console.log("submitted");
  }

  beforeDestroy() {
    this.userCodeInput = 0;
  }

  created() {
    this.skipOnSuccessReturn();
  }
}
